<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'防疫员：' + fangYiYaunDetailData.name" @selectJob="selectJob"/>

    <!-- body -->
    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_5_p h_full chart_box_bgi_2 bgs_full p_10">
        <!-- 个人信息 -->
        <div class="w_full h_49_p">
          <div class="w_full h_50_p">
            <div class="w_30_p h_full m_0_auto">
              <img 
                class="border_radius_10 cursor response_img" 
                :src='filePath + `${fangYiYaunDetailData.avatar}`' alt=""
              >
            </div>
          </div>

          <div class="w_full h_50_p color_fff pv_20 border_box">
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">姓名：<span class="main_color">{{ fangYiYaunDetailData.name }}</span> </div>
              <!-- <div class="h_24 lh_24 mb_3">民族：<span class="main_color">{{ fangYiYaunDetailData.nationName || '蒙古族' }}</span> </div> -->
              <!-- <div class="h_24 lh_24 mb_3">性别：<span class="main_color">{{ fangYiYaunDetailData.xxxxx || '男' }}</span></div> -->
            </div>

            <div class="w_full pr_20">
              <div class="h_24 lh_24 mb_3 ellipsis_text_2">负责地区：<span class="main_color">{{ fangYiYaunDetailData.remark }}</span></div>
            </div>
            
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">
                电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(fangYiYaunDetailData.phone) }}</span>
              </div>
            </div>

            <div class="flex_row_between pr_20">
              <div 
                class="h_24 lh_24 mb_3 cursor jian_bian_text_3 fw_bold" 
                @click="goToFangYiGuiJi"
              >
                签到轨迹
              </div>

              <div 
                class="h_24 lh_24 mb_3 cursor jian_bian_text_3 fw_bold" 
                @click="goToPage(`/mianYiTaiZhang/${fangYiYaunDetailData.name}`)"
              >
                免疫台账
              </div>

              <div 
                class="h_24 lh_24 mb_3 cursor jian_bian_text_3 fw_bold" 
                @click="goToPage(`/erpPerson/${fangYiYaunDetailData.storeId}/${fangYiYaunDetailData.storeName}/${userId}`)"
              >
                疫苗库存
              </div>

              <div 
                class="h_24 lh_24 mb_3 ellipsis_text_2 cursor jian_bian_text_3 fw_bold" 
                @click="goToFangYiHuJiao()"
              >
                呼叫
              </div>
            </div>
          </div>
        </div>

        <div class="w_full h_49_p scroll_box overflow_y_auto">
          <pre 
            class="w_full full scroll_box overflow_y_auto text_indent_2 color_fff text_1 wb_break_all" 
            v-if="fangYiYaunDetailData.content && fangYiYaunDetailData.content.length != 11">
            {{ fangYiYaunDetailData.content }}
          </pre>
        </div>
      </div>

      <!-- 2 -->
      <div class="w_32_5_p h_full flex_column_between">
        <fang-yi-yuan-mian-yi-yi-miao-chart 
          :antiepidemicPersonId="userId" 
          :jobId="jobId"
        />
      </div> 

      <!-- 3 -->
      <div class="w_32_5_p h_full list_box_bgi_1 bgs_full">
        <div class="full p_10">
          <div class="w_full text_left">
            <div class="w_100 jian_bian_text_1 text_1 fw_bold">免疫记录</div>
          </div>

          <div class="w_full p_10 flex_row_start">           
            <div class="w_30_p">
              <el-select 
                class="w_full_i" 
                v-model="muHuId" size="mini" clearable 
                placeholder="牧户" filterable
                @change="selectMuHu"
                @clear="getRecordsYiListByPersonId(userId, jobId)"
              >
                <el-option
                  v-for="item in userNameList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId">
                </el-option>
              </el-select>
            </div>

            <div class="w_30_p ml_10">
              <el-select 
                class="w_full_i" 
                v-model="animalId" size="mini" clearable 
                placeholder="动物种类" filterable
                @change="selectAnimal"
                @clear="getRecordsYiListByPersonId(userId, jobId)"
              >
                <el-option
                  v-for="item in animalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="w_full h_full-60 flex_column_between">
            <div class="scroll_box w_full h_full-10 overflow_y_auto">
              <dv-loading v-if="!filterUserList.length" class="color_fff">数据加载中...</dv-loading>

              <div 
                class="w_full text_1 pv_10 ph_10 color_fff border_b_1_s_main_color hover_main_bgc cursor" 
                v-for="(item, index) in filterUserList" :key="index"
                @click="handleClickAnimalName(item.userId, item.userName, item.animalId)"
              >
                <div class="w_full flex_row_between p_10">
                  <div class="w_160 h_180">
                    <img class="border_radius_5 response_img" :src="filePath + item.signPicture1" alt="">
                  </div>

                  <div class="w_full ph_10">
                    <div class="">
                      <span class="">免疫日期：</span>
                      <span class="main_color font_number">{{ formatDate(item.dateTime) }}</span> 
                    </div>
                  <div class="p_2 cursor" @click="goToMuHuPage('null', 'null', 'null', item.userId)">
                    <span class="">牧户：</span>
                    <span class="main_color">{{ item.userName }}</span>
                  </div>
                  <div class="w_full p_2">
                    <span class="">免疫疫苗：</span>
                    <span class="theme_color_green">{{ item.vaccinumName }}</span>
                  </div>
                  <div class="p_2">
                    <span class="">免疫动物类型：</span>
                    <span class="main_color">{{ item.animalName }}</span>
                  </div>
                  <div class="p_2">
                    <span class="">动物存栏量：</span>
                    <span class="main_color font_number">{{ item.animalCount }}</span>
                  </div>
                  <div class="p_2">
                    <span class="">免疫动物数量：</span>
                    <span class="main_color font_number">{{ item.fangYiCount }}</span>
                  </div>
                  <!-- <div class="w_full p_2">
                    <span class="">备注：</span>
                    <span class="main_color">{{ item.remark }}</span>
                  </div> -->
                  </div>
                </div>
              </div>
            </div> 

            <!-- 分页 -->
            <!-- <div class="w_full h_35">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :page-sizes="[50, 100, 200, 300]"
                layout="total, sizes, prev, pager, next"
                :total="total">
              </el-pagination>
            </div> -->
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import { 
    getAntiepidemicPersonInfo, 
    getRecordsYiListByPersonId
  } from '@/api/fang-yi/fang-yi-yuan.js';
  import { getAnimalList } from '@/api/animal/index.js'
  
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components.js'
  import echartMixin from '@/mixins/echart.js'
  import dataChartMixin from '@/mixins/data-charts.js'

  import { filePath } from '@/const.js'
  
  export default {
    name: 'FangYiYuanPage',
    mixins: [ defMixin, componentsMixin, echartMixin, dataChartMixin ],
    data() {
      return {
        filePath: filePath,

        storeId: null,
        storeName: null,
        userId: null,

        jobId: null,

        animalId: null,
        animalList: [],

        muHuId: null,
        userList: [],
        userNameList: [],

        filterUserList: [],
        filterParams: {
          jobId: '',
          userId: '',
          animalId: ''
        },

        fangYiYaunDetailData: {},
        farmList: [],
      }
    },
    async mounted() {
      let userId = this.$route.params.val
      this.userId = userId

      this.getAnimalList()

      // 获取 防疫员 详情
      this.getAntiepidemicPersonInfo(userId)

      // 通过 防疫员 id 获取 防疫员 防疫的【牧户】列表
      this.getRecordsYiListByPersonId(userId, this.jobId, this.animalId)
    },
    methods: {
      selectJob(jobId) {
        this.jobId = jobId;
      },

      // 获取 防疫员详情
      async getAntiepidemicPersonInfo(userId) {
        let res = await getAntiepidemicPersonInfo(userId)
        try {
          if (res.code == 1000) {
            this.fangYiYaunDetailData = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取动物列表
      async getAnimalList() {
        let res = await getAnimalList()
        try {
          if (res.code == 1000) {
            this.animalList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 防疫员的 防疫台账记录
      async getRecordsYiListByPersonId(personId, jobId, animalId) {
        let res = await getRecordsYiListByPersonId(personId, jobId, animalId)
        try {
          if (res.code == 1000) {
            this.userList = res.data
            // this.filterUserList = res.data

            if (this.fangYiYaunDetailData.name == '代钦') {
              this.filterUserList = res.data.slice(2)
            } else {
              this.filterUserList = res.data
            }

            let userNameList = []
            res.data.map(item => {
              userNameList.push({ userId: item.userId, userName: item.userName })
              this.userNameList = Array.from(new Set(userNameList.map(JSON.stringify)), JSON.parse);
            })
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 选择 牧户
      selectMuHu(val) {
        this.filterParams.userId = val
        this.filterUserList = []

        this.filterUserList = this.filterParams.animalId  ? 
          this.userList.filter(item => item.animalId == this.filterParams.animalId && item.userId == val) 
          : 
          this.userList.filter(item => item.userId == val)
      },

      // 选择 动物
      selectAnimal(val) {
        this.filterParams.animalId = val

        this.filterUserList = []

        this.filterUserList = this.filterParams.userId  ? 
          this.userList.filter(item => item.userId == this.filterParams.userId && item.animalId == val) 
          : 
          this.userList.filter(item => item.animalId == val)
      },

      // 跳转到 防疫轨迹页面
      goToFangYiGuiJi() {
        this.$router.push({
          path: `/fangYiGuiJi/${this.userId}`
        })
      },

      // 跳转到 防疫呼叫页面
      goToFangYiHuJiao() {
        this.$router.push({
          path: `/fangYiHuJiao`
        })
      },

      // 跳转页面
      goToPage(path) {
        this.$router.push({
          path
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .w_20_p { font-size: 18px; } 
  }
</style>
